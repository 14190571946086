$(document).ready(function() {
  var currentIndex = 1;
  $(".main").onepage_scroll({
    animationTime: 750,
    sectionContainer: "section",
    responsiveFallback: false,
    loop: false,
    pagination: false,
    beforeMove: function(index) {
      if (index != currentIndex) {
        $('.section-content').removeClass('aos-animate');

        sectionContainer = $('.section-' + index + ' > .section-content');
        currentSectionContainer = $('.section-' + currentIndex + ' > .section-content');

        currentSectionContainer[0].dataset.aos = index > currentIndex ? "fade-down" : "fade-up";

        sectionContainer.addClass('aos-animate');
        currentIndex = index;

        $('.scroll-spy > div[data-id]').removeClass('focus');
        $('.scroll-spy > div[data-id=' + currentIndex +']').addClass('focus');
      }
    }
  });

  AOS.init({
    duration: 1000,
    easing: 'ease-in-out',
  });

  $('#explore-button').on('click', function() {
    $(".main").moveDown();
  });

  $('.scroll-spy > div[data-id]').on('click', function() {
    $(".main").moveTo($(this).data('id'));
  });
});
